<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano de Curso
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card color="primary" outlined>
          <v-card-text style="color: #fff; font-size: 15px; font-weight: bolder">
            <h4>Turma: {{ gestaoDeAula.turma?.descricao }}</h4>
            <h4>
              Disciplina:
              {{
                gestaoDeAula.is_polivalencia === true
                  ? gestaoDeAula.disciplina
                  : gestaoDeAula.disciplina.descricao
              }}
            </h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </h4>
            <h4>
              Ano: {{ gestaoDeAula.turma ? gestaoDeAula.turma.ano?.descricao : "[carregando...]" }}
            </h4>
            <h4>Turno: {{ gestaoDeAula.turma?.turno?.descricao }}</h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(createOrUpdatePlanoDeCurso)"
        :disabled="submittingForm"
        v-if="loadPlano"
      >
        <v-row>
          <v-col cols="12">
            <ValidationProvider name="Tematica" rules="required" v-slot="{ errors }">
              <e-label>IDENTIFICAÇÃO</e-label>
              <v-textarea solo dense v-model="plano.identificacao" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>RECURSOS </e-label>
              <v-textarea solo dense v-model="plano.recursos" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
              <e-label>PROCEDIMENTOS AVALIATIVOS</e-label>
              <v-textarea solo dense v-model="plano.procedimentos" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <e-label>REFERÊNCIAS</e-label>
              <v-textarea solo dense v-model="plano.referencias" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <v-btn color="success" class="ml-3" @click="dialog = true">Exportar plano</v-btn>
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary">
            <span style="color: #fff" class="text-h5">Exportar Plano</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(exportarPlano)" :disabled="submittingForm">
                  <v-row>
                    <v-col class="pt-4 pb-0" cols="12">
                      <e-label class="font-weight-medium">Turma</e-label>
                      <p class="font-weight-regular">
                        <v-select
                          :items="turmas"
                          item-text="turma.descricao"
                          item-value="turma.id"
                          return-object
                          label="Selecione o campo abaixo"
                          v-model="turmaSelecionada"
                          @change="(item) => setGestao(item)"
                        ></v-select>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <e-label class="font-weight-medium">IDENTIFICAÇÃO</e-label>
                      <p class="font-weight-regular">
                        {{ plano.identificacao }}
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <e-label class="font-weight-medium">RECURSOS</e-label>
                      <p class="font-weight-regular">
                        {{ plano.recursos }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn style="color: #fff" class="red darken-1" @click="dialog = false">
                      Fechar
                    </v-btn>
                    <v-btn
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      style="color: #fff"
                      class="green darken-1"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <e-modal-confirm
        :confirmDialog="confirmDialog"
        :objectModal="objectModal"
        @changeModalConfirm="enableSubmit"
      >
      </e-modal-confirm>
    </v-row>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },

  mounted() {
    this.loadData();
  },

  data() {
    return {
      loadPlano: true,
      enableSubmit: true,
      dialog: false,
      confirmDialog: false,
      turmas: [],
      turma: null,
      disciplina: null,
      professor: null,
      submittingForm: false,
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      objectModal: {
        title: "Exportar plano",
        message: "Tem certeza que deseja exportar esta plano?",
      },
      export_gestao: null,
      export_disciplina_id: null,
      plano: {},
      planoExportarPlano: {
        disciplina_id: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
        disciplinas: [],
      },
    };
  },
  methods: {
    async editarPlano(bimestre) {
      this.loadPlano = true;
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        this.plano.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        await this.$services.planosBimestraisService.createOrUpdatePlanoDeCurso(this.plano);
        const response = await this.$services.planosBimestraisService.planoBimestral(
          gestaoDeAulaId
        );
        if (response !== "") {
          this.plano = response;
          return;
        }
        this.plano = {
          bimestre,
        };
        this.loadPlano = true;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },

    setGestao(item) {
      this.export_gestao = item.id;
      this.export_disciplina_id = item.disciplina_id;
    },

    async createOrUpdatePlanoDeCurso() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { disciplinaId } = this.$route.params;
        this.plano.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.plano.disciplina_id = disciplinaId;
        await this.$services.planosBimestraisService.createOrUpdatePlanoDeCurso(this.plano);
        this.$toast.success("Plano de curso salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async exportarPlano() {
      this.$loaderService.open("Exportando plano...");
      try {
        const { disciplinaId } = this.$route.params;
        this.planoExportarPlano.instrutorDisciplinaTurma_id = this.export_gestao;
        this.planoExportarPlano.disciplina_id = disciplinaId;
        this.planoExportarPlano.identificacao = this.plano.identificacao;
        this.planoExportarPlano.recursos = this.plano.recursos;
        this.planoExportarPlano.procedimentos = this.plano.procedimentos;
        this.planoExportarPlano.referencias = this.plano.referencias;
        await this.$services.planosBimestraisService.createOrUpdatePlanoDeCurso(
          this.planoExportarPlano
        );

        this.$toast.success("Plano de curso exportado com sucesso!");

        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const { gestaoDeAulaId } = this.$route.params;
        this.disciplinaId = parseInt(this.$route.params.disciplinaId, 10);
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);

        const payload = await this.$services.planosBimestraisService.getPlanoCurso(
          parseInt(this.$route.params.gestaoDeAulaId, 10),
          this.disciplinaId
        );
        const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
          this.gestaoDeAula
        );
        this.plano = payload.plano || {};
        this.turmas = gestoes;

        if (this.gestaoDeAula.disciplinas.length > 0) {
          const disciplina = this.gestaoDeAula.disciplinas.filter((i) => {
            return i.id === this.disciplinaId;
          });

          this.gestaoDeAula.disciplina = disciplina[0].descricao;
        }

        if (payload !== "") {
          this.form = payload;
        }

        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>

<style></style>
